// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Default icons are required
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/paste';


// copy tinymce's skins directory into public/packs
require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/skins',
  true,
  /.*/
);


function tinyMCE() {
  //### TINYMCE ###//
    tinymce.init({
      selector: 'textarea.tinymce',
      relative_urls: false,
      menubar: false,
      plugins: ['visualblocks emoticons autolink'],
      toolbar: 'bold | italic | emoticons',
      entity_encoding : "raw",
      removeformat : [
        { selector : '*', attributes : ['style', 'class'], split : false, expand : false, deep : true }
      ],
      paste_enable_default_filters: false,
      paste_word_valid_elements: "b,strong,i,em",
      paste_auto_cleanup_on_paste : true,
      visualblocks_default_state: true,
      end_container_on_empty_block: true,
      valid_elements : "a[href|target=_blank],strong/b,i/em,p,br,ul,ol,li"
    });
  //####################//


  //### TINYMCE ###//
    tinymce.init({
      selector: 'textarea.tinymce-slim',
      relative_urls: false,
      menubar: false,
      plugins: ['visualblocks'],
      toolbar: 'bold | italic',
      entity_encoding : "raw",
      removeformat : [
        { selector : '*', attributes : ['style', 'class'], split : false, expand : false, deep : true }
      ],
      paste_enable_default_filters: false,
      paste_word_valid_elements: "b,strong,i,em",
      paste_auto_cleanup_on_paste : true,
      visualblocks_default_state: true,
      end_container_on_empty_block: true,
      valid_elements : "strong/b,i/em,p,br"
    });
  //####################//
}

export { tinyMCE };