import consumer from "./consumer"


jQuery(document).ready(function($){
    consumer.subscriptions.create({channel: "DateChatChannel", room: $('#messages').attr('data-date-chatroom')}, {
        connected() {
            scroll_bottom()
            add_class_message_author()
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
        },

        received(data) {
            // console.log(data);

            let message_container = $('#message-container')

            if (data.left_chat) {
                message_container.append(data.left_chat)
                $('#chat_users>.users').html(data.users.join(', '))
            }
            if (data.enter_chat) {
                message_container.append(data.enter_chat)
                $('#chat_users>.users').html(data.users.join(', '))
            }
            if (data.closed_chat) {
                message_container.append(data.closed_chat)

                setTimeout(function() {
                    location.reload();
                }, 5000);

            }
            if (data.chat_outoftime) {
                message_container.append(data.chat_outoftime)

                setTimeout(function() {
                    location.reload();
                }, 10000);

            }
            if (data.mod_date_message) {
                message_container.append(data.mod_date_message)
                clear_field(data.author)
            }


            scroll_bottom()
            add_class_message_author()
        },


        createLine(data) {
            return data
        }

    })

})


import {add_class_message_author, scroll_bottom, clear_field} from '../components/chat';
