
export function clear_field(author) {
    $('[data-content-user-id=' + author + ']').val('');
}

export function scroll_bottom() {

    let messages = $('#messages');

    if (messages.length > 0) {
        let height = messages[0].scrollHeight;
        window.scrollTo(0, height);
    }
}

export function add_class_message_author() {
    let user_id = $('#message-container').data('user-id')
    $(".mt-3").find(`[data-user-id='${user_id}']`).addClass("message-author")
}