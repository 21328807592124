// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")


import $ from 'jquery';
window.$ = jQuery;


// TINYMCE
import { tinyMCE } from "components/tinyMCE";
tinyMCE();


// CUSTOM
jQuery(document).ready(function($){
  // SET NAVIGATION ACTIVE
  var url = window.location;
  var element = $('ul.navbar-nav a').filter(function() {
      return this.href == url || url.href.indexOf(this.href) == 0;
  }).parent().addClass('active').parent().addClass('show').parent();
  if (element.is('li')) {
    element.addClass('active').find('a').first().attr("aria-expanded","true");
  }


  // SCROLL TOP
  // - CLICK FUNCTION
  $('.scroll-to-top').click(function() {
    $('html, body').animate({ scrollTop: 0 },'slow');
  });
  // - RESIZE / SCROLL TOP
  $(window).on('resize scroll', function() {
    if ($(this).scrollTop() > 100){
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });
});
