import consumer from "./consumer"


jQuery(document).ready(function($){
  consumer.subscriptions.create({ channel: "ChatRoomChannel", room: $('#messages').attr('data-chat-room') }, {
    connected() {
    },

    received(data) {
      this.appendLine(data)
    },

    appendLine(data) {
      const html = this.createLine(data)
      const element = document.querySelector("[data-chat-room]")
      element.insertAdjacentHTML("beforeend", html)

      window.scrollTo(0, element.scrollHeight);
    },

    createLine(data) {
      return data
    }
  })
})
