import consumer from "./consumer"

jQuery(document).ready(function($){
    const live_conversation = consumer.subscriptions.create({channel: "LiveChatChannel", conversation: $('#messages').attr('data-live-conversation'), current_office: $('#current_office').attr('data-office-id')}, {
        connected() {
            scroll_bottom()
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
        },

        received(data) {

            let message_container = $('#message-container')

            if (data.closed_chat) {
                message_container.append(data.closed_chat)

                setTimeout(function() {
                    location.href = "/chat/live_conversation/closed";
                }, 5000);

            }

            if (data.mod_date_message) {
                message_container.append(data.mod_date_message)
                clear_field(data.author)
            }

            if (data.mod_created_at) {

                $(".last-message-" + data.conversation_id).html(data.mod_created_at)
                $(".last-message-short-" + data.conversation_id).html(data.mod_created_at_short)
                $(".tab-conversation-" + data.conversation_id).addClass("bg-warning text-white")
            }

            if (data.new_live_conversation) {
                $('#live_chat_alert').append(data.new_live_conversation)
                $('.show-conversations-list').append(data.new_live_conversation_tab)

            }

            scroll_bottom()
        },


        createLine(data) {
            return data
        }

    })

})


import {scroll_bottom, clear_field} from '../components/chat';
